import { ForwardRefRenderFunction, PropsWithChildren } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Fluid = styled.div<{
  $isMobile,
  $mobileWithPadding?
  $withPadding?
}>(({
  theme,
  $isMobile,
  $mobileWithPadding,
  $withPadding,
}) => `
  ${theme.breakpoints.down('lg')} {
    padding-left: ${theme.spacing($mobileWithPadding ? 2 : 0)};
    padding-right: ${theme.spacing($mobileWithPadding ? 2 : 0)};
  }
  width: 100%;
  height: auto;
  padding-left: ${theme.spacing($withPadding ? 16.5 : 0)};
  padding-right: ${theme.spacing($withPadding ? 16.5 : 0)};
`);

interface FluidContainerProps {
  style?: React.CSSProperties;
  mobileWithPadding?: boolean;
  withPadding?: boolean;
}

const Container: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<FluidContainerProps>> = (
  {
    children,
    style,
    mobileWithPadding,
    withPadding,
  }: PropsWithChildren<FluidContainerProps>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return <Fluid
    ref={ref}
    $withPadding={withPadding}
    $mobileWithPadding={mobileWithPadding}
    $isMobile={isMobile}
    id="fluid-container"
    style={style}
    data-test-id="fluid-container">
    { children }
  </Fluid>
  ;
};

const FluidContainer = React.forwardRef(
  Container
);

FluidContainer.displayName = 'FluidContainer';

export default FluidContainer;
