/* eslint-disable sonarjs/no-duplicate-string */
import { PortableText } from '@portabletext/react';
import { urlForImage } from 'utils/imageUtils';
import portableTextComponents from 'components/widgets/portableComponents';
import styled from '@emotion/styled';
import theme from 'theme';

const HeroGrid = styled.div<{
  $layout
}>(({
  $layout,
}) => `
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    height: auto;
    &.full-width {
      position: relative;
    }
  } 
  ${theme.breakpoints.between('sm', 'xl')} {
    height: 600px;
  }
  width: 100%;
  height: 880px;
  display: flex;
  flex-direction: ${$layout ? 'row' : 'row-reverse'};
  flex-wrap: nowrap;
  justify-content: flex-start;
  &.full-width {
    position: relative;
  }
`);

const MainImageContainer = styled.div`
  ${theme.breakpoints.down('sm')} {
    width: 100%;
    height: 360px;
  }
  width: 60%;
  height: 100%;
  position: relative;
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  &.full-width {
    width: 100%;
    z-index: -1;
  }
`;

const Side = styled.div<{
  $sideLayout
}>(({
  $sideLayout,
}) => `
  ${theme.breakpoints.down('sm')} {
    width: 100%;
    height: 720px;
  }
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: ${$sideLayout ? 'column' : 'column-reverse'};
`);

const SecondaryImageContainer = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
`;

const SecondaryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextBg = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  background-color: ${theme.palette.secondary.main};
`;

const Text = styled.div`
  ${theme.breakpoints.down('lg')} {
    min-width: 100%;
    padding: ${theme.spacing(0, 2)};
  }
  max-width: 60%;
  display: block;
  font-size: 2rem;
  line-height: 2.375rem;
  letter-spacing: 0.16px;
  color: #fff;
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &> h1 {
    ${theme.breakpoints.down('lg')} {
      font-size: 1.875rem;
      line-height: 2.375rem;
    }
    font-size: 3.125rem;
    line-height: 3.4375rem;
    font-weight: 500;
    text-align: center;
  }
  &> h2 {
    ${theme.breakpoints.down('lg')} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    font-size: 2rem;
    line-height: 2.375rem;
    font-weight: 600;
    text-align: center;
  }
  &> p {
    ${theme.breakpoints.down('lg')} {
      font-size: 1.25rem;
      line-height: 1.3125rem;
    }
    font-weight: 200;
    text-align: left;
  }
  /* link */
  &> p > a {
    ${theme.breakpoints.down('lg')} {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 11px 20px;
    }
    display: block;
    width: max-content;
    border-radius: 50px;
    border: solid 1px #fff;
    color: #fff;
    padding: 14px 20px;
    :hover {
      background-color: #C61C1C;
      border: solid 1px #C61C1C;
    }
  }
`;

interface SanityHero {
  data: any; // TODO: typing
}

const SanityHero = ({
  data,
}: SanityHero) => {
  const layout = () => data.layout == 'image-right';
  const sideLayout = () => data.sideLayout == 'image-top';

  if (data == null) {
    return null;
  }

  return <>
    {
      data.layout == 'image-background' ?

        /* 1 image */
        <HeroGrid className='full-width' $layout={layout()}>
          <Text>
            <PortableText
              value={data?.mainImageText}
              components={portableTextComponents}
            />
          </Text>
          <MainImage
            className='full-width'
            src={urlForImage(data.mainImage).url()}
            alt={data.alt}
          />
        </HeroGrid> :

        /* 2 images and a text box */
        <HeroGrid $layout={layout()}>
          <Side $sideLayout={sideLayout()}>
            <SecondaryImageContainer>
              <Text >
                <PortableText
                  value={data?.secondaryImageText}
                  components={portableTextComponents}
                />
              </Text>
              <SecondaryImage
                src={urlForImage(data.secondaryImage).url()}
              />
            </SecondaryImageContainer>
            <TextBg>
              <Text>
                <PortableText
                  value={data?.textBlock}
                  components={portableTextComponents}
                />
              </Text>
            </TextBg>
          </Side>
          <MainImageContainer>
            <Text>
              <PortableText
                value={data?.mainImageText}
                components={portableTextComponents}
              />
            </Text>
            <MainImage
              src={urlForImage(data.mainImage).url()}
              alt={data.alt}
            />
          </MainImageContainer>
        </HeroGrid>
    }
  </>;
};
export default SanityHero;
