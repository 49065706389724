import { PortableText } from '@portabletext/react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import portableTextComponents from 'components/widgets/portableComponents';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const HeaderContainer = styled.div`
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(4, 0, 2, 0)};
  }
  width: 100%;
  display: flex;
  flex-description: column;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(8, 10, 0, 10)};
`;

/* TODO: clean up the styling of text elements */
const SanityGrid = styled.div`
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(1, 0)};
  }
  width: 100%;
  display: flex;
  padding: ${theme.spacing(3, 10, 8, 10)};
  /* h1 */
  &> div > div > div > h1 {
    ${theme.breakpoints.down('sm')}{
      font-size: 1rem;
      line-height: 1.5rem;
    }
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.625rem;
    letter-spacing: 0.1px;
  }
  /* h2 */
  &> div > div > div > h2 {
    ${theme.breakpoints.down('sm')}{
      font-size: 1rem;
      line-height: 1.5rem;
    }
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  /* h3 */
  &> div > div > div > h3 {
    ${theme.breakpoints.down('sm')}{
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.07px;
    }
    font-size: 0.9375rem;
    line-height: 1.3125rem;
    letter-spacing: 0.07px;
    font-weight: 500;
  }
  /* p */
  &> div > div > div > p {
    ${theme.breakpoints.down('sm')}{
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.07px;
    }
    font-size: 0.9375rem;
    line-height: 1.3125rem;
    letter-spacing: 0.07px;
  }
`;

interface SanityTextGrid {
  header?: any;
  title?: any;
  content?: any
}

const SanityTextGrid = ({
  header,
  title,
  content,
}: SanityTextGrid) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return <>
    <HeaderContainer>
      <Grid container item rowGap={isMobile ? 2 : 5} sm={12}>
        <Grid item xs={12}>
          <Typography variant='h4'>
            {header}
          </Typography>
        </Grid>
        {
          title && <Grid item xs={12}>
            <Typography variant='h1'>
              {title}
            </Typography>
          </Grid>
        }
      </Grid>
    </HeaderContainer>
    <SanityGrid>
      <PortableText
        value={content}
        components={portableTextComponents}
      />
    </SanityGrid>
  </>;
};
export default SanityTextGrid;
